import Channel from "../models/entities/channel";
import { ChannelType } from "../models/entities/post";
import ChannelConfig from "../modules/social-sets/channels/models/channel-config";

const channelConfig: ChannelConfig[] = [
  {
    name: "Facebook",
    description: "Facebook Page",
    imageUrl: "/images/social-platforms/facebook.svg",
    type: "Facebook",
    tags: [],
  },
  {
    name: "Instagram",
    description: "Business or Creator account",
    imageUrl: "/images/social-platforms/instagram.svg",
    type: "Instagram",
    tags: [],
  },
  {
    name: "X",
    description: "X Profile",
    imageUrl: "/images/social-platforms/x.svg",
    type: "Twitter",
    tags: [],
  },
  {
    name: "Pinterest",
    description: "Business or Personal account",
    imageUrl: "/images/social-platforms/pinterest.svg",
    type: "Pinterest",
    tags: [],
  },
  {
    name: "TikTok",
    description: "Business or Creator account",
    imageUrl: "/images/social-platforms/tiktok.svg",
    type: "TikTok",
    tags: [],
  },
  {
    name: "YouTube",
    description: "Channel",
    imageUrl: "/images/social-platforms/youtube.svg",
    type: "YouTube",
    tags: [],
  },
  {
    name: "LinkedIn",
    description: "Page or Profile",
    imageUrl: "/images/social-platforms/linkedin.svg",
    type: "LinkedIn",
    tags: [],
  },
  {
    name: "Threads",
    description: "Profile",
    imageUrl: "/images/social-platforms/threads.svg",
    type: "Threads",
    tags: [],
  },
];

const findChannelConfig = (type: ChannelType): ChannelConfig => {
  return channelConfig.find((x) => x.type == type);
};

const getChannelImageUrl = (channel?: Channel): string => {
  return channel?.pictureUrl ?? getChannelTypeImageUrl(channel);
};

const getChannelTypeImageUrl = (channel?: Channel): string => {
  return (
    findChannelConfig(channel?.type)?.imageUrl ?? channelConfig[0].imageUrl
  );
};

const getChannelTypeImageUrlFromType = (channelType?: ChannelType): string => {
  return findChannelConfig(channelType)?.imageUrl ?? channelConfig[0].imageUrl;
};

export {
  findChannelConfig,
  channelConfig,
  getChannelImageUrl,
  getChannelTypeImageUrl,
  getChannelTypeImageUrlFromType,
};
