/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from "react";
import { ThreadsContentInstance } from "../../../../../models/entities/post";
import AttachmentSlot from "../../models/attachment-slot";
import { PostInstance } from "../../models/post-instance";

interface ThreadsSyncerProps {
  attachments: AttachmentSlot[];
  postInstance: PostInstance;
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
}

export default function ThreadsSyncer({
  attachments,
  postInstance,
  onPostInstanceChanged,
}: ThreadsSyncerProps) {
  const threadsConfig = postInstance.postConfig.threads;
  const prevAttachmentsRef = useRef();

  useEffect(() => {
    const setSystemPostType = (contentInstance: ThreadsContentInstance) => {
      let systemPostType = contentInstance.systemPostType;

      const initialSystemPostType = systemPostType;
      const attachmentIds = contentInstance.attachmentIds;
      const instanceAttachments = attachments.filter(
        (a) => a.attachment && attachmentIds.includes(a.attachment.id)
      );

      if (instanceAttachments.length == 0) {
        systemPostType = "TextPost";
      } else if (instanceAttachments.length == 1) {
        systemPostType = instanceAttachments[0].isPhoto
          ? "PhotoPost"
          : "VideoPost";
      } else if (instanceAttachments.length > 1) {
        systemPostType = "CarouselPost";
      }

      if (systemPostType != initialSystemPostType) {
        const updatedInstance: ThreadsContentInstance = {
          ...contentInstance,
          systemPostType: systemPostType,
        };

        const updatedContentInstances = [
          ...postInstance.postConfig.threads.contentOptions.instances,
        ];
        const index = updatedContentInstances.findIndex(
          (i) => i.id === contentInstance.id
        );

        if (index !== -1) {
          updatedContentInstances[index] = updatedInstance;
        }

        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            threads: {
              contentOptions: {
                ...threadsConfig.contentOptions,
                instances: updatedContentInstances,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    threadsConfig.contentOptions.instances.forEach((instance) => {
      setSystemPostType(instance);
    });
  }, [threadsConfig.contentOptions, attachments, postInstance]);

  useEffect(() => {
    const prevValue = (prevAttachmentsRef.current as AttachmentSlot[]) ?? [];

    // Filter out selected attachments which are not in the current attachment list
    threadsConfig.contentOptions.instances.forEach((instance) => {
      instance.attachmentIds = instance.attachmentIds.filter((id) =>
        attachments
          .filter((a) => a.attachment)
          .map((a) => a.attachment.id)
          .includes(id)
      );
    });

    // Find newly added attachments and assign them to the current instance
    const newAttachments = attachments
      .filter(
        (a) =>
          !prevValue
            .filter((x) => x.attachment)
            .map((x) => x.attachment?.id)
            .includes(a.attachment?.id)
      )
      .filter((a) => a.attachment);

    if (newAttachments.length > 0) {
      const updatedContentInstances = [
        ...postInstance.postConfig.threads.contentOptions.instances,
      ];

      const currentInstance = updatedContentInstances.find((x) => x.current);

      if (!currentInstance) {
        console.warn(
          "No current instance found while syncing threads channel",
          updatedContentInstances
        );
        return;
      }

      const index = currentInstance.index;

      const updatedContentInstance: ThreadsContentInstance = {
        ...currentInstance,
        attachmentIds: currentInstance.attachmentIds.concat(
          newAttachments.map((a) => a.attachment.id)
        ),
      };

      if (index !== -1) {
        updatedContentInstances[index] = updatedContentInstance;
      }

      const updatedPostInstance: PostInstance = {
        ...postInstance,
        postConfig: {
          ...postInstance.postConfig,
          threads: {
            contentOptions: {
              ...threadsConfig.contentOptions,
              instances: updatedContentInstances,
            },
          },
        },
      };

      onPostInstanceChanged(updatedPostInstance, true);
    }

    (prevAttachmentsRef as any).current = attachments.filter(
      (x) => x.attachment
    );
  }, [
    attachments,
    onPostInstanceChanged,
    postInstance,
    threadsConfig.contentOptions,
  ]);

  return null;
}
