import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";

export default function EntetprisePricing() {
  return (
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-black transform -skew-y-2 rounded-3xl shadow-xl">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-[#e03058]/20 via-transparent to-transparent"></div>
      </div>
      <div className="relative px-8 py-12 sm:px-12 lg:px-16 flex flex-col lg:flex-row items-center justify-between">
        <div className="text-center lg:text-left mb-8 lg:mb-0">
          <div className="flex items-center justify-center lg:justify-start mb-4">
            <BuildingOffice2Icon className="h-10 w-10 text-white" />
            <span className="ml-3 text-[#e03058] text-lg font-semibold">
              Enterprise Plan
            </span>
          </div>
          <h2 className="text-3xl font-bold text-white mb-4">
            Need a custom solution?
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl">
            Get a tailored plan that perfectly fits your organization's needs.
            Our enterprise solutions include unlimited resources, dedicated
            support, custom integrations, and flexible terms.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 lg:ml-8">
          <a
            href={`mailto:contact@viraly.io?subject=${encodeURIComponent(
              "Enterprise Plan Inquiry"
            )}&body=${encodeURIComponent(
              "Hi,\n\nI am interested in learning more about your Enterprise plan. Here are some details about our needs:\n\n- Company size:\n- Current challenges:\n- Key requirements:\n\nBest regards,"
            )}`}
            className="group border-white border px-8 py-4 rounded-xl text-white font-semibold hover:bg-primary-600 hover:border-primary-600 transition-colors flex items-center justify-center shadow-md hover:shadow-lg"
          >
            Contact Sales
            <ArrowRightIcon className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
          </a>
        </div>
      </div>
    </div>
  );
}
