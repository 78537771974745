import Button from "../../../../components/common/Button";
import { Label, Radio, RadioGroup } from "@headlessui/react";
import StripeProduct from "../../../../models/stripe/product";
import StripePrice from "../../../../models/stripe/price";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { frequencies } from "../../../../data/plans";
import stripeService from "../../../../services/api/stripe-service";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import StripeSubscription from "../../../../models/stripe/subscription";
import GenericDialog from "../../../../components/dialogs/GenericDialog";
import alertService from "../../../../services/alert/alert-service";
import ComparePlans from "./ComparePlans";
import { delay } from "../../../../utils/shared-utils";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import { useSearchParams } from "react-router-dom";
import StripePaymentMethod from "../../../../models/stripe/payment-method";
import PlanActivatedDialog from "./PlanActivatedDialog";
import Spinner from "../../../../components/common/Spinner";
import dayjs from "dayjs";
import { getDecimalDigit } from "../../../../utils/math-utils";
import { channelConfig } from "../../../../data/channels-config";
import EntetprisePricing from "./EnterprisePricing";

const sections = [
  {
    id: "influencer",
    planName: "Influencer",
    subHeader: "Influencer plan includes...",
    features: [
      {
        id: "feature0",
        name: (
          <div className="flex flex-col gap-1.5">
            <div>Post Scheduling to 8 platforms</div>
            <ul className="flex items-center gap-2">
              {channelConfig.map((channel) => (
                <li key={channel.type}>
                  <img src={channel.imageUrl} className="w-6 h-6" />
                </li>
              ))}
            </ul>
          </div>
        ), //Instagram, Facebook, X, YouTube, TikTok, LinkedIn, Pinterest",
      },
      { id: "feature1", name: "Analytics & Reporting" },
      { id: "feature2", name: "Hashtag lists" },
      { id: "feature3", name: "Media library" },
      { id: "feature4", name: "Content queues" },
      { id: "feature5", name: "First comment" },
      { id: "feature6", name: "Up to 5 channels" },
      { id: "feature7", name: "Up to 200 posts per month" },
      { id: "feature8", name: "Up 6 months analytics data" },
      { id: "feature9", name: "Basic email support" },
    ],
  },
  {
    id: "business",
    planName: "Business",
    subHeader: "Everything in Influencer, plus...",
    features: [
      { id: "feature1", name: "Team access" },
      { id: "feature2", name: "Up to 15 social accounts" },
      { id: "feature3", name: "Up to 500 posts per month" },
      { id: "feature4", name: "Up to 5 team members" },
      { id: "feature5", name: "Up 12 months analytics data" },
      { id: "feature6", name: "Role assignments & permissions" },
      { id: "feature7", name: "Real-time collaboration" },
      { id: "feature8", name: "Priority support" },
    ],
  },
  {
    id: "agency",
    planName: "Agency",
    subHeader: "Everything in Business, plus...",
    features: [
      { id: "feature1", name: "Up to 50 social accounts" },
      { id: "feature2", name: "Up to 15 team members" },
      { id: "feature3", name: "Unlimited posts" },
      { id: "feature4", name: "Up 2 years analytics data" },
      { id: "feature5", name: "Video customer support" },
      { id: "feature6", name: "Dedicated account manager" },
    ],
  },
];

export interface PlanListProps {
  products: StripeProduct[];
  prices: StripePrice[];
  paymentMethods: StripePaymentMethod[];
  subscription: StripeSubscription;
  onSubscriptionUpdated: (subscription: StripeSubscription) => void;
}

export default function PlanList({
  products,
  prices,
  subscription,
  paymentMethods,
  onSubscriptionUpdated,
}: PlanListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [frequency, setFrequency] = useState(frequencies[1]);
  const [selectedProduct, setSelectedProduct] = useState<StripeProduct>();
  const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isActivating, setIsActivating] = useState<boolean>(false);
  const [submittingProduct, setSubmittingProduct] =
    useState<StripeProduct>(null);
  const [activateLoadingLabel, setActivateLoadingLabel] =
    useState<string>(null);
  const comparisonTableDialog = useRef(null);
  const planActivatedDialogRef = useRef(null);
  const cancelDialogRef = useRef(null);
  const renewDialogRef = useRef(null);

  const tierPricing: { monthly: StripeProduct[]; yearly: StripeProduct[] } =
    useMemo(() => {
      if (!products || !prices) {
        return {
          monthly: [],
          yearly: [],
        };
      }

      return {
        monthly: products.map((product) => {
          const result = {
            ...product,
            defaultPrice:
              prices.find(
                (x) =>
                  x.productId == product.id && x.recurring.interval == "month"
              ) ?? product.defaultPrice,
          };

          result.finalPrice = { ...result.defaultPrice };
          result.originalPrice = { ...result.defaultPrice };

          return result;
        }),
        yearly: products.map((product) => {
          const result = {
            ...product,
            defaultPrice:
              prices.find(
                (x) =>
                  x.productId == product.id && x.recurring.interval == "year"
              ) ?? product.defaultPrice,
          };

          result.finalPrice = { ...result.defaultPrice };
          result.originalPrice = { ...result.defaultPrice };

          return result;
        }),
      };
    }, [prices, products]);

  useEffect(() => {
    const currentFrequency = frequencies.find(
      (x) => x.stripeInterval == subscription?.items?.[0]?.plan?.interval
    );

    if (currentFrequency) {
      setFrequency(currentFrequency);

      const product = tierPricing[currentFrequency.value].find(
        (x) => x.id == subscription?.items?.[0]?.price.productId
      ) as StripeProduct;
      if (product) {
        product.defaultPrice = { ...subscription.items?.[0].price };
        product.finalPrice = { ...product.defaultPrice };
        product.originalPrice = { ...product.defaultPrice };
        product.discounts = subscription.items?.[0].discounts;

        const item = subscription.items?.[0];

        if (item?.discounts?.length) {
          const coupon = item.discounts[0]?.coupon;
          if (coupon?.appliesTo?.products?.includes(item.plan.productId)) {
            const discountedPrice =
              product.finalPrice.id == item.price.id
                ? product.finalPrice
                : null;

            if (discountedPrice) {
              if (coupon.percentOff) {
                discountedPrice.unitAmount -=
                  (coupon.percentOff * discountedPrice.unitAmount) / 100;
                discountedPrice.unitAmountDecimal -=
                  (coupon.percentOff * discountedPrice.unitAmountDecimal) / 100;
              } else if (coupon.amountOff) {
                discountedPrice.unitAmount -= coupon.amountOff;
                discountedPrice.unitAmountDecimal -= coupon.amountOff;
              }
            }
          }
        }

        setSelectedProduct(product);
      }
    }
  }, [subscription, tierPricing]);

  useEffect(() => {
    const action = searchParams.get("action");
    const status = searchParams.get("status");
    const priceId = searchParams.get("priceId");

    if (action == "activate") {
      if (status == "success") {
        setIsActivating(true);
        onEndTrial(null);
      } else {
        alertService.warn("Activating subscription was canceled.");
      }
    } else if (action == "changePlan") {
      if (priceId == subscription?.items?.[0]?.price.id) {
        planActivatedDialogRef.current!.openDialog("PlanChanged");
      }
    }

    setSearchParams({});
  }, [subscription]);

  const checkoutSetup = async () => {
    const successUrl = `${window.location.origin}/settings/plan?action=activate&status=success`;
    const cancelUrl = `${window.location.origin}/settings/plan?action=activate&status=canceled`;

    const checkoutSession = await stripeService.getSetupCheckoutSession(
      successUrl,
      cancelUrl
    );

    window.location.href = checkoutSession.url;
  };

  const onConfirmCancel = async () => {
    setIsLoadingCancel(true);
    setIsSubmitting(true);

    try {
      const subscription = await stripeService.cancelSubscription();

      await delay(1500);
      await onSubscriptionUpdated(subscription);

      alertService.success(
        `Your plan will be canceled on ${dayjs(subscription.cancelAt).format(
          "MMM DD, YYYY"
        )}`
      );
    } finally {
      setIsLoadingCancel(false);
      setIsSubmitting(false);
    }
  };

  const onCancel = async () => {
    cancelDialogRef.current?.openDialog();
  };

  const onConfirmRenew = async () => {
    setIsSubmitting(true);

    try {
      const subscription = await stripeService.restoreSubscription();

      await delay(1500);
      await onSubscriptionUpdated(subscription);

      alertService.success("Subscription successfully reactivated");
    } finally {
      setIsSubmitting(false);
    }
  };

  const onRestore = async () => {
    renewDialogRef.current?.openDialog();
  };

  // const checkout = async (product: StripeProduct) => {
  //   const successUrl = `${window.location.origin}/settings/plan?action=changePlan&status=success`;
  //   const cancelUrl = `${window.location.origin}/settings/plan?action=changePlan&status=canceled`;

  //   const checkoutSession = await stripeService.getSetupCheckoutSession(
  //     successUrl,
  //     cancelUrl
  //   );

  //   window.location.href = checkoutSession.url;
  // }

  const onUpdate = async (product: StripeProduct) => {
    setIsLoadingUpdate(true);
    setSubmittingProduct(product);
    setIsSubmitting(true);

    try {
      const returnUrl = `${window.location.origin}/settings/plan?action=changePlan&priceId=${product.defaultPrice.id}`;

      const customerPortalSession = await stripeService.getCustomerPortal(
        returnUrl,
        "subscription_update_confirm",
        product.defaultPrice.id
      );

      window.location.href = customerPortalSession.url;
    } finally {
      setIsLoadingUpdate(false);
      setIsSubmitting(false);
      setSubmittingProduct(null);
    }
  };

  const onEndTrial = async (product: StripeProduct) => {
    setSubmittingProduct(product);
    setIsSubmitting(true);

    try {
      if (!paymentMethods.length) {
        setActivateLoadingLabel("Redirecting");
        await checkoutSetup();
        return;
      }

      setActivateLoadingLabel("Activating");

      const subscription = await stripeService.endTrialEarly(
        paymentMethods[0].id
      );

      await delay(1500);
      await onSubscriptionUpdated(subscription);

      planActivatedDialogRef.current!.openDialog("SubscriptionActivated");
    } finally {
      setIsSubmitting(false);
      setSubmittingProduct(null);
      setIsActivating(false);
    }
  };

  const checkout = async (product: StripeProduct) => {
    setSubmittingProduct(product);
    setIsSubmitting(true);

    try {
      // const returnUrl = `${window.location.origin}/settings/billing`;
      const customerPortalSession = await stripeService.getCheckoutSession(
        product.finalPrice.id,
        false
      );

      window.location.href = customerPortalSession.url;
    } finally {
      setIsSubmitting(false);
      setSubmittingProduct(null);
    }
  };

  return (
    <>
      {/* Frequency toggles */}
      <div className="mt-6 flex justify-center">
        <RadioGroup
          value={frequency}
          onChange={setFrequency}
          className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
        >
          <Label className="sr-only">Payment frequency</Label>
          {frequencies.map((option) => (
            <Radio
              key={option.value}
              value={option}
              className={clsx(
                option.value == frequency.value
                  ? "bg-primary-600 text-white"
                  : "text-gray-500",
                "cursor-pointer rounded-full px-2.5 py-1"
              )}
            >
              <span>{option.label}</span>
            </Radio>
          ))}
        </RadioGroup>
      </div>

      {/* Trialing banner  */}
      {subscription?.status == "trialing" && (
        <div className="rounded-md bg-blue-50 p-4 mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                aria-hidden="true"
                className="h-5 w-5 text-blue-400"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                You are currently on a free trial of the <strong>Agency</strong>{" "}
                plan. Select which plan you would like to activate.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Cancelation banner */}
      {subscription?.cancelAt && subscription?.status != "canceled" && (
        <div className="rounded-md bg-orange-50 p-4 mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                aria-hidden="true"
                className="h-5 w-5 text-orange-400"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-orange-700">
                Your{" "}
                <span className="font-medium">
                  {subscription?.items?.[0]?.plan?.product?.name}
                </span>{" "}
                {subscription?.items?.[0]?.plan?.interval == "year"
                  ? "yearly"
                  : "monthly"}{" "}
                plan will be canceled on{" "}
                <span className="font-medium">
                  {" "}
                  {dayjs(subscription.cancelAt).format("MMM DD, YYYY")}
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Canceled banner */}
      {subscription?.status == "canceled" && (
        <div className="rounded-md bg-orange-50 p-4 mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                aria-hidden="true"
                className="h-5 w-5 text-orange-400"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-orange-700">
                Your{" "}
                <span className="font-medium">
                  {subscription?.items?.[0]?.plan?.product?.name}
                </span>{" "}
                {subscription?.items?.[0]?.plan?.interval == "year"
                  ? "yearly"
                  : "monthly"}{" "}
                plan was canceled on{" "}
                <span className="font-medium">
                  {" "}
                  {dayjs(subscription.canceledAt).format("MMM DD, YYYY")}
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Applied discounts */}
      {[...tierPricing.monthly, ...tierPricing.yearly].some(
        (x) => x.finalPrice.unitAmount != x.originalPrice.unitAmount
      ) && (
        <div className="rounded-md bg-green-50 p-4 mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-green-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.99 14.993 6-6m6 3.001c0 1.268-.63 2.39-1.593 3.069a3.746 3.746 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043 3.745 3.745 0 0 1-3.068 1.593c-1.268 0-2.39-.63-3.068-1.593a3.745 3.745 0 0 1-3.296-1.043 3.746 3.746 0 0 1-1.043-3.297 3.746 3.746 0 0 1-1.593-3.068c0-1.268.63-2.39 1.593-3.068a3.746 3.746 0 0 1 1.043-3.297 3.745 3.745 0 0 1 3.296-1.042 3.745 3.745 0 0 1 3.068-1.594c1.268 0 2.39.63 3.068 1.593a3.745 3.745 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.297 3.746 3.746 0 0 1 1.593 3.068ZM9.74 9.743h.008v.007H9.74v-.007Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Promo coupon applied.
              </h3>
              <div className="mt-1">
                <p className="text-sm text-green-700">
                  {subscription?.items?.[0]?.discounts[0].coupon.name}
                </p>
                <p className="text-xs mt-2 text-green-800">
                  Expires on&nbsp;
                  {dayjs(subscription?.items?.[0]?.discounts[0].end).format(
                    "MMM DD, YYYY"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Plans list */}
      <div className="isolate w-full mx-auto mt-10 grid grid-cols-1 gap-8 lg:mx-0 md:max-w-none md:grid-cols-3 max-w-5xl">
        {tierPricing[frequency.value].map((product: StripeProduct) => (
          <div
            key={product.id}
            className={clsx(
              product?.defaultPrice?.id == selectedProduct?.defaultPrice?.id &&
                subscription?.status != "trialing" &&
                subscription?.status != "canceled"
                ? "ring-2 ring-primary-600"
                : "ring-1 ring-gray-200",
              "flex flex-col rounded-xl p-6 transition-all"
            )}
          >
            <div className="relative flex flex-col items-center justify-start gap-x-4">
              <h3
                id={product.id}
                className={clsx(
                  product?.defaultPrice?.id ==
                    selectedProduct?.defaultPrice?.id &&
                    subscription?.status != "trialing" &&
                    subscription?.status != "canceled"
                    ? "text-primary-600"
                    : "text-gray-900",
                  "text-lg font-semibold leading-8"
                )}
              >
                {product.name}
              </h3>
              {product?.defaultPrice?.id == selectedProduct?.defaultPrice?.id &&
                subscription?.status != "trialing" &&
                subscription?.status != "canceled" && (
                  <div>
                    <CheckCircleIcon className="w-6 absolute -top-5 -right-5 text-primary-600" />
                  </div>
                )}
            </div>

            <div className="mt-6 flex flex-col justify-center items-center">
              {/* Price if no discount is applied */}
              {product.originalPrice.unitAmount ==
                product.finalPrice.unitAmount && (
                <>
                  {frequency.stripeInterval == "month" && (
                    <>
                      <span className="text-4xl font-bold">
                        ${product.finalPrice.unitAmount / 100}
                      </span>
                      <span className="text-sm font-light leading-6 text-center">
                        {" "}
                        {frequency.priceSuffix}
                      </span>
                    </>
                  )}

                  {frequency.stripeInterval == "year" && (
                    <>
                      <div className="flex items-start gap-x-1">
                        <span className="text-4xl font-bold">
                          $
                          {Math.floor(product.finalPrice.unitAmount / 12 / 100)}
                        </span>
                        <span className="text-xs font-medium">
                          {getDecimalDigit(
                            product.finalPrice.unitAmount / 12 / 100
                          )}
                        </span>
                      </div>
                      <span className="text-sm font-light leading-6">
                        per month, billed annualy
                      </span>
                    </>
                  )}
                </>
              )}
              {/* Price if discount is applied */}
              {product.originalPrice.unitAmount !=
                product.finalPrice.unitAmount && (
                <div className="flex items-center gap-2 relative">
                  <p className="absolute -top-6 flex flex-wrap items-baseline line-through">
                    <span className="text-md font-semibold tracking-tight text-gray-900">
                      ${product.originalPrice.unitAmount / 100}
                    </span>
                    &nbsp;
                    <span className="text-xs font-normal leading-6 text-gray-600">
                      {frequency.priceSuffix}
                    </span>
                  </p>

                  <p className="flex flex-wrap items-baseline gap-x-1">
                    <span className="text-lg font-semibold tracking-tight text-gray-900">
                      {product.finalPrice.unitAmount == 0 ? (
                        "Free "
                      ) : (
                        <>${product.finalPrice.unitAmount / 100}</>
                      )}
                    </span>
                    <span className="text-sm font-normal leading-6 text-gray-600">
                      for{" "}
                      {
                        subscription.items?.[0].discounts[0].coupon
                          .durationInMonths
                      }{" "}
                      months
                    </span>
                  </p>
                </div>
              )}
            </div>

            {/* Buttons */}
            <div className="mt-6 flex flex-col">
              {/* Show Activate only if subscription is not currently canceled, is trialing and is currently selected */}
              {/* TRIALING STATUS */}
              {subscription &&
                !subscription.cancelAt &&
                subscription.status == "trialing" && (
                  <>
                    {product?.defaultPrice?.id ==
                      subscription?.items?.[0]?.price.id && (
                      <Button
                        variant="outline"
                        color="brand"
                        text="Select Plan"
                        onClick={() => onEndTrial(product)}
                        loading={submittingProduct?.id == product.id}
                        disabled={isSubmitting}
                        loadingText={activateLoadingLabel}
                      />
                    )}

                    {product?.defaultPrice?.id !=
                      subscription?.items?.[0]?.price.id && (
                      <Button
                        variant="outline"
                        color="brand"
                        text="Select Plan"
                        loadingText="Redirecting..."
                        onClick={() => onUpdate(product)}
                        loading={submittingProduct?.id == product.id}
                        disabled={isSubmitting}
                      />
                    )}
                  </>
                )}

              {/* Show Update if either the subscription is not canceled or if it is canceled but it's not currently selected */}
              {/* ACTIVE STATUS */}
              {subscription.status != "canceled" && (
                <>
                  {subscription &&
                    !subscription.cancelAt &&
                    subscription.status != "trialing" && (
                      <>
                        {product?.defaultPrice?.id !=
                          subscription?.items?.[0]?.price.id && (
                          <>
                            <Button
                              variant="outline"
                              color="brand"
                              text="Change Plan"
                              loadingText="Loading"
                              onClick={() => onUpdate(product)}
                              loading={
                                isLoadingUpdate &&
                                submittingProduct?.id == product.id
                              }
                              disabled={isSubmitting}
                            />
                          </>
                        )}
                        {product?.defaultPrice?.id ==
                          subscription?.items?.[0]?.price.id && (
                          <>
                            <Button
                              style={{ cursor: "not-allowed" }}
                              data-tooltip-id="tooltip-placeholder"
                              data-tooltip-content="You are currently on this plan"
                              className="cursor-not-allowed"
                              variant="solid"
                              color="brand"
                              text="Current plan"
                              onClick={() => onUpdate(product)}
                              loading={submittingProduct?.id == product.id}
                              disabled={
                                isSubmitting ||
                                product?.defaultPrice?.id ==
                                  subscription?.items?.[0]?.price.id
                              }
                            />
                          </>
                        )}
                      </>
                    )}
                </>
              )}

              {/* Allow to select plan and trigger checkout if subscription is canceled */}
              {/* CANCELED STATUS */}
              {subscription.status == "canceled" && (
                <Button
                  variant="outline"
                  color="brand"
                  text="Select Plan"
                  loadingText="Redirecting"
                  onClick={() => checkout(product)}
                  loading={submittingProduct?.id == product.id}
                  disabled={isSubmitting}
                />
              )}
            </div>

            <h6 className="mt-6 text-sm font-semibold leading-6">
              {
                sections.find((section) => section.planName === product.name)
                  ?.subHeader
              }
            </h6>

            <ul
              role="list"
              className="mt-4 space-y-4 text-sm leading-6 text-gray-600"
            >
              {sections
                .find((section) => section.planName === product.name)
                ?.features.map((feature) => (
                  <li key={feature.id} className="flex gap-x-3">
                    <div className="h-5 w-5 mt-0.5 rounded-full bg-primary-600/10 flex items-center justify-center">
                      <svg
                        className="h-3.5 w-3.5 text-primary-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    {feature.name}
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="mt-10 relative flex justify-center gap-4">
        <Button
          onClick={() => comparisonTableDialog.current.openDialog()}
          text="Compare all features"
          variant="text"
          color="brand"
        />
      </div>

      <div className="mt-10">
        <EntetprisePricing />
      </div>

      <div className="w-full flex gap-2 justify-end mt-16">
        {/* Show Renew only if subscription is canceled and currently selected */}
        {subscription &&
          subscription.cancelAt &&
          subscription.status != "canceled" && (
            <>
              {/* {selectedProduct?.id ==
              subscription?.items?.[0]?.price.productId && ( */}
              <Button
                variant="solid"
                color="brand"
                text="Reactivate subscription"
                loadingText="Reactivating"
                onClick={() => onRestore()}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
              {/* )} */}
              {/* {selectedProduct?.id !=
              subscription?.items?.[0]?.price.productId && (
              <Button
                variant="solid"
                color="brand"
                text="Change plan & Restore"
                onClick={() => onUpdate()}
                loading={isSubmitting}
                loadingText="Loading..."
                disabled={isSubmitting}
              />
            )} */}
            </>
          )}

        {/* Show Cancel only if subscription is not currently canceled, is not trialing and is currently selected */}
        {subscription && !subscription.cancelAt && !subscription.canceledAt && (
          <>
            <Button
              variant="outline"
              color="brand"
              text="Cancel subscription"
              loadingText="Canceling"
              onClick={() => onCancel()}
              loading={isLoadingCancel}
              disabled={isSubmitting}
            />
          </>
        )}
      </div>

      <GenericDialog ref={comparisonTableDialog}>
        <div className="max-h-[90vh] overflow-auto">
          <ComparePlans
            showButtons={!subscription}
            onClose={() => comparisonTableDialog.current.closeDialog()}
          />
        </div>
      </GenericDialog>

      <PlanActivatedDialog ref={planActivatedDialogRef} />

      <ConfirmDialog
        title="Cancel subscription?"
        cancelLabel="Nevermind"
        confirmLabel="Confirm cancelation"
        message={`You will still have access to your <span className="font-medium">${
          subscription?.items?.[0]?.plan?.product?.name
        }</span> 
        ${
          subscription?.items?.[0]?.plan?.interval == "year"
            ? "yearly"
            : "monthly"
        } plan through 
        <span className="font-medium"> ${dayjs(
          subscription.currentPeriodEnd
        ).format(
          "MMM DD, YYYY"
        )}</span>. Any scheduled posts beyond this date will not be sent.`}
        ref={cancelDialogRef}
        onConfirm={onConfirmCancel}
      />

      <ConfirmDialog
        title="Reactivate subscription?"
        message={`Your recurring <span className="font-medium">${
          subscription?.items?.[0]?.plan?.product?.name
        }</span> 
                ${
                  subscription?.items?.[0]?.plan?.interval == "year"
                    ? "yearly"
                    : "monthly"
                } plan will be restored immediately  and will not bill again until 
                <span className="font-medium"> ${dayjs(
                  subscription.currentPeriodEnd
                ).format("MMM DD, YYYY")}</span>.`}
        ref={renewDialogRef}
        onConfirm={onConfirmRenew}
      />

      {isActivating && (
        <div className="flex flex-col justify-center absolute -left-1 -top-1 -right-1 -bottom-1 bg-white">
          <Spinner />
          <div className="text-sm text-center leading-8 text-gray-600">
            Activating subscription
          </div>
        </div>
      )}
    </>
  );
}
