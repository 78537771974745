import { CheckIcon, MinusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";
import {
  FeatureCode,
  featureSections,
  frequencies,
  TierFeature,
  tierFeatures,
} from "../../../../data/plans";
import { useQuery } from "@tanstack/react-query";
import StripeProduct from "../../../../models/stripe/product";
import queryNames from "../../../../queries/query-names";
import stripeService from "../../../../services/api/stripe-service";
import { Label, Radio, RadioGroup } from "@headlessui/react";
import Spinner from "../../../../components/common/Spinner";
import Button from "../../../../components/common/Button";
import { getDecimalDigit } from "../../../../utils/math-utils";
import EntetprisePricing from "./EnterprisePricing";

interface ComparePlanProps {
  showButtons: boolean;
  onClose: () => void;
}

export default function ComparePlans({
  showButtons,
  onClose,
}: ComparePlanProps) {
  const [frequency, setFrequency] = useState(frequencies[1]);
  const [loadingProduct, setLoadingProduct] = useState<StripeProduct>();

  const { data: products, isLoading: isLoadingProducts } = useQuery({
    queryKey: [queryNames.stripeProducts],
    queryFn: stripeService.listProducts,
  });

  const { data: prices, isLoading: isLoadingPrices } = useQuery({
    queryKey: [queryNames.stripePrices],
    queryFn: stripeService.listPrices,
  });

  const tierPricing: { monthly: StripeProduct[]; yearly: StripeProduct[] } =
    !products || !prices
      ? {
          monthly: [],
          yearly: [],
        }
      : {
          monthly: products.map((product) => {
            return {
              ...product,
              defaultPrice:
                prices.find(
                  (x) =>
                    x.productId == product.id && x.recurring.interval == "month"
                ) ?? product.defaultPrice,
            };
          }),
          yearly: products.map((product) => {
            return {
              ...product,
              defaultPrice:
                prices.find(
                  (x) =>
                    x.productId == product.id && x.recurring.interval == "year"
                ) ?? product.defaultPrice,
            };
          }),
        };

  const featureComponent = (tier: StripeProduct, feature: FeatureCode) => {
    const featureTier = tier.metadata["tier"];
    const featureValue = getTierFeatureValue(featureTier, feature);

    if (featureValue?.value === true) {
      return <CheckIcon className="mx-auto h-5 w-5 text-primary-600" />;
    } else if (featureValue?.value === false) {
      return <MinusIcon className="mx-auto h-5 w-5 text-gray-400" />;
    } else {
      return (
        <div className="text-sm leading-6 text-gray-950 text-center">
          {featureValue.value}
        </div>
      );
    }
  };

  const selectProduct = async (product: StripeProduct) => {
    setLoadingProduct(product);

    try {
      const checkoutSession = await stripeService.getCheckoutSession(
        product.defaultPrice.id,
        false
      );
      window.location.href = checkoutSession.url;
    } finally {
      setLoadingProduct(null);
    }
  };

  const getTierFeatureValue = (
    tier: string,
    feature: string
  ): TierFeature | null => {
    return tierFeatures[tier].find((x) => x.feature == feature);
  };

  return (
    <>
      <div className="mx-auto max-w-7xl py-4 lg:py-8 lg:flex lg:gap-x-16 lg:px-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg bg-white">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-lg font-semibold leading-7 text-primary-600">
              Compare Features
            </h2>
          </div>

          <div className="absolute top-0 right-0 z-10">
            <XMarkIcon
              className="w-10 mt-4 mr-4 hover:bg-gray-50 text-slate-700 rounded-full cursor-pointer p-2"
              onClick={onClose}
            />
          </div>

          {/* Toggle frequency */}
          <div className="mt-8 flex justify-center">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              <Label className="sr-only">Payment frequency</Label>
              {frequencies.map((option) => (
                <Radio
                  key={option.value}
                  value={option}
                  className={clsx(
                    option.value == frequency.value
                      ? "bg-primary-600 text-white"
                      : "text-gray-500",
                    "cursor-pointer rounded-full px-2.5 py-1"
                  )}
                >
                  <span>{option.label}</span>
                </Radio>
              ))}
            </RadioGroup>
          </div>

          {isLoadingPrices || isLoadingProducts ? (
            <div className="my-12">
              <Spinner size="5" />
            </div>
          ) : (
            <>
              {/* xs to lg */}
              <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
                {tierPricing[frequency.value].map((tier: StripeProduct) => (
                  <section
                    key={tier.id}
                    className="p-8 rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200"
                  >
                    <h3
                      id={tier.id}
                      className="text-sm text-center font-semibold leading-6 text-gray-900"
                    >
                      {tier.name}
                    </h3>
                    <div className="mt-2 flex items-center justify-center flex-wrap gap-x-1 text-gray-900">
                      <div className="flex flex-col items-center justify-center text-gray-900">
                        {frequency.stripeInterval == "month" && (
                          <>
                            <span className="text-4xl font-bold">
                              ${tier.defaultPrice.unitAmount / 100}
                            </span>
                            <span className="text-sm font-light leading-6 text-center">
                              {frequency.priceSuffix}
                            </span>
                          </>
                        )}

                        {frequency.stripeInterval == "year" && (
                          <>
                            <div className="flex items-start gap-x-1">
                              <span className="text-4xl font-bold">
                                $
                                {Math.floor(
                                  tier.defaultPrice.unitAmount / 12 / 100
                                )}
                              </span>
                              <span className="text-xs font-medium">
                                {getDecimalDigit(
                                  tier.defaultPrice.unitAmount / 12 / 100
                                )}
                              </span>
                            </div>
                            <span className="text-sm font-light leading-6">
                              per month, billed annualy
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    {showButtons && (
                      <a
                        aria-describedby={tier.id}
                        className={clsx(
                          "text-primary-600 ring-1 ring-inset ring-primary-200 hover:ring-primary-300",
                          "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                        )}
                      >
                        Subscribe
                      </a>
                    )}

                    <ul
                      role="list"
                      className="mt-10 space-y-4 text-sm leading-6 text-gray-900"
                    >
                      {featureSections.map((section) => (
                        <li key={section.id}>
                          <ul role="list" className="space-y-4">
                            {section.features.map((feature) => (
                              <li
                                key={feature.id}
                                className="w-full flex gap-x-3"
                              >
                                <div className="w-full flex items-center gap-3">
                                  {feature.image && (
                                    <img
                                      src={feature.image}
                                      alt={feature.name}
                                      className="w-6 h-6"
                                    />
                                  )}
                                  <span>{feature.name}</span>

                                  <div className="ml-auto">
                                    {featureComponent(tier, feature.id)}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </section>
                ))}
              </div>

              {/* lg+ */}
              <div className="isolate mt-20 hidden lg:block">
                <div className="relative -mx-8">
                  <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                    <caption className="sr-only">
                      Pricing plan comparison
                    </caption>
                    <colgroup>
                      <col className="w-1/4" />
                      <col className="w-1/4" />
                      <col className="w-1/4" />
                      <col className="w-1/4" />
                    </colgroup>
                    <thead>
                      <tr>
                        <td />
                        {tierPricing[frequency.value].map(
                          (tier: StripeProduct) => (
                            <th
                              key={tier.id}
                              scope="col"
                              className="px-6 pt-6 xl:px-8 xl:pt-8"
                            >
                              <div className="text-sm font-semibold leading-7 text-gray-900 text-center">
                                {tier.name}
                              </div>
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <span className="sr-only">Price</span>
                        </th>
                        {tierPricing[frequency.value].map(
                          (tier: StripeProduct) => (
                            <td key={tier.id} className="px-6 pt-2 xl:px-8">
                              <div className="flex flex-col items-center justify-center text-gray-900">
                                {frequency.stripeInterval == "month" && (
                                  <>
                                    <span className="text-4xl font-bold">
                                      ${tier.defaultPrice.unitAmount / 100}
                                    </span>
                                    <span className="text-sm font-light leading-6 text-center">
                                      {frequency.priceSuffix}
                                    </span>
                                  </>
                                )}

                                {frequency.stripeInterval == "year" && (
                                  <>
                                    <div className="flex items-start gap-x-1">
                                      <span className="text-4xl font-bold">
                                        $
                                        {Math.floor(
                                          tier.defaultPrice.unitAmount /
                                            12 /
                                            100
                                        )}
                                      </span>
                                      <span className="text-xs font-medium">
                                        {getDecimalDigit(
                                          tier.defaultPrice.unitAmount /
                                            12 /
                                            100
                                        )}
                                      </span>
                                    </div>
                                    <span className="text-sm font-light leading-6">
                                      per month, billed annualy
                                    </span>
                                  </>
                                )}
                              </div>
                              {showButtons && (
                                <Button
                                  className="mt-8 w-full"
                                  variant="outline"
                                  color="brand"
                                  text="Subscribe"
                                  onClick={() => selectProduct(tier)}
                                  disabled={!!loadingProduct}
                                  loading={loadingProduct?.id == tier.id}
                                />
                              )}
                            </td>
                          )
                        )}
                      </tr>

                      {featureSections.map((section, sectionIdx) => (
                        <Fragment key={section.name}>
                          <tr>
                            <th
                              scope="colgroup"
                              colSpan={4}
                              className={clsx(
                                sectionIdx === 0 ? "pt-8" : "pt-16",
                                "pb-4 text-sm font-semibold leading-6 text-gray-900"
                              )}
                            >
                              {section.name}
                              <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                            </th>
                          </tr>
                          {section.features.map((feature) => (
                            <tr key={feature.name}>
                              <th
                                scope="row"
                                className="py-4 text-sm font-normal leading-6 text-gray-900"
                              >
                                <div className="flex items-center gap-3">
                                  {feature.image && (
                                    <img
                                      src={feature.image}
                                      className="w-6 h-6"
                                    />
                                  )}
                                  {feature.name}
                                </div>
                                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                              </th>
                              {tierPricing[frequency.value].map(
                                (tier: StripeProduct) => (
                                  <td
                                    key={tier.id}
                                    className="px-6 py-4 xl:px-8"
                                  >
                                    {featureComponent(tier, feature.id)}
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}

          <div className="mt-10">
            <EntetprisePricing />
          </div>
        </div>
      </div>
    </>
  );
}
