/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import channelProviderService from "../../../services/application/channel-provider-service";
import errorReporter from "../../../utils/error-reporter";
import channelService from "../../../services/api/channel-service";
import { refetchQuery } from "../../../queries/query-utils";
import queryNames from "../../../queries/query-names";
import Spinner from "../../../components/common/Spinner";
import { ChannelType } from "../../../models/entities/post";
import OAuthConstants from "../../../constants/oauth";
import QueryParamConstants from "../../../constants/query-params";
import Channel from "../../../models/entities/channel";
import AccountPickerDialog from "../../social-sets/channels/components/AccountPickerDialog";
import AccountPickerErrorDialog from "../../social-sets/channels/components/AccountPickerErrorDialog";
import RefreshChannelErrorDialog from "../../social-sets/channels/components/RefreshChannelErrorDialog";
import AccountPickerItem from "../../social-sets/channels/models/account-picker-item";
import ChannelData from "../../social-sets/channels/models/channel-data";
import sessionBrowserStorage from "../../../services/browser-storage/session-browser-storage";
import useFragmentParams from "../../../hooks/useFragmentParams";
import { findChannelConfig } from "../../../data/channels-config";

export interface OAuthCallbackProps {
  channelType: ChannelType;
  stateProvider?: "QueryParams" | "SessionStorage";
  callbackParams?: string[];
  useFragments?: boolean;
}

type StateData = {
  action: string;
  socialSetId: string;
  channelId?: string;
  externalChannelId?: string;
};

export default function OAuthCallback({
  channelType,
  stateProvider = "QueryParams",
  callbackParams = [],
  useFragments = false,
}: OAuthCallbackProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [stateData, setStateData] = useState<StateData>(null);
  const [accountPickerItems, setAccountPickerItems] = useState<
    AccountPickerItem[]
  >([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fragmentParams = useFragmentParams();

  const hasFragments = !!fragmentParams.get("state");

  const queryParams = useMemo(
    () => (useFragments && hasFragments ? fragmentParams : searchParams),
    [fragmentParams, hasFragments, searchParams, useFragments]
  );

  // Load state from query params or session storage
  const state = useMemo(
    () =>
      stateProvider == "QueryParams"
        ? queryParams.get("state")
        : sessionBrowserStorage.getItem("oauthCallbackState"),
    [queryParams, stateProvider]
  );

  // Load error from query params
  const error = useMemo(() => queryParams.get("error"), [queryParams]);

  // Load oauth params from query params
  const oauthParams = useMemo(() => {
    return callbackParams
      .map((x) => ({ key: x, value: queryParams.get(x) }))
      .filter((x) => !!x.value);
  }, [callbackParams, queryParams]);

  // Load channel config
  const channelConfig = useMemo(
    () => findChannelConfig(channelType),
    [channelType]
  );

  useEffect(() => {
    const process = async (): Promise<void> => {
      try {
        if (!state) {
          return;
        }

        // Parse state data
        const stateData = parseStateData(state);
        setStateData(stateData);

        if (error || oauthParams.length == 0) {
          setIsError(true);
          return;
        }

        setIsLoading(true);

        // Fetch channels
        // Only fetch availabilities when connecting channels, not when refreshing
        const fetchAvailabilities =
          stateData.action == OAuthConstants.ConnectChannel;

        const accountPickerItems = await channelProviderService.listChannels(
          channelType,
          oauthParams,
          fetchAvailabilities
        );

        setAccountPickerItems(accountPickerItems);

        // Handle refresh channel action
        if (stateData.action == OAuthConstants.RefreshChannel) {
          const refreshChannel = accountPickerItems.find(
            (x) => x.id == stateData.externalChannelId
          );
          if (!refreshChannel) {
            throw new Error("Channel to refresh was not found");
          }

          await onRefreshConnection([refreshChannel], stateData);
        }
      } catch (error) {
        console.error(`Error fetching ${channelType} channels`, error);
        setIsLoading(false);
        setIsConnecting(false);
        setIsError(true);

        errorReporter.alertErrors(error);
      } finally {
        setIsLoading(false);
      }
    };

    process();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelType, error, oauthParams, state]);

  const goBack = () => {
    navigate(`/social-sets/${stateData.socialSetId}`, { replace: true });
  };

  const onChannelsChosen = async (
    accounts: AccountPickerItem[]
  ): Promise<void> => {
    setIsLoading(false);
    setIsConnecting(true);

    const channelsData: ChannelData[] = accounts.map((account) => ({
      type: channelType,
      externalId: account.id,
      channel: account.channel,
      name: account.name,
      username: account.username,
      accountType: account.data.accountType.key,
      pictureUrl: account.picture,
      data: account.data,
    }));

    try {
      const channels = await channelsConnected(channelsData);

      refetchQuery([queryNames.socialSets]);
      redirect(stateData, channels);
    } finally {
      setIsConnecting(false);
    }
  };

  const onRefreshConnection = async (
    accounts: AccountPickerItem[],
    stateData: StateData
  ): Promise<void> => {
    setIsLoading(false);
    setIsConnecting(true);

    const channels: ChannelData[] = accounts.map((account) => ({
      type: channelType,
      externalId: account.id,
      channel: account.channel,
      name: account.name,
      username: account.username,
      accountType: account.data.accountType.key,
      pictureUrl: account.picture,
      data: account.data,
    }));

    const channelData = channels[0];

    const channel = await channelService.update(stateData.channelId, {
      name: channelData.name,
      username: channelData.username,
      externalId: channelData.externalId,
      socialSetId: stateData.socialSetId,
      type: channelData.type,
      accountType: channelData.accountType,
      pictureUrl: channelData.pictureUrl,
      config: channelData.data as any,
    });

    refetchQuery([queryNames.socialSets]);
    redirect(stateData, [channel]);
  };

  const redirect = (stateData, channels: Channel[]) => {
    navigate(
      `/social-sets/${stateData.socialSetId}?${
        QueryParamConstants.ConnectedChannelType
      }=${channelConfig.type}&${QueryParamConstants.Action}=${
        stateData.action
      }&${QueryParamConstants.ConnectedChannelId}=${channels
        .map((x) => x.id)
        .join(",")}`,
      { replace: true }
    );
  };

  const parseStateData = (state: string): StateData => {
    const data = JSON.parse(state);

    const action = data["action"];
    const socialSetId = data["socialSetId"];
    const channelId = data["channelId"];
    const externalChannelId = data["externalChannelId"];

    if (
      action != OAuthConstants.ConnectChannel &&
      action != OAuthConstants.RefreshChannel
    ) {
      throw new Error("Valid action not found in OAuth state");
    }

    if (!socialSetId) {
      throw new Error("Social set not found in OAuth state");
    }

    if (action == OAuthConstants.RefreshChannel) {
      if (!channelId) {
        throw new Error("Channel not found in OAuth state");
      }

      if (!externalChannelId) {
        throw new Error("External channel not found in OAuth state");
      }
    }

    return {
      action,
      socialSetId,
      channelId,
      externalChannelId,
    };
  };

  const channelsConnected = async (
    channels: ChannelData[]
  ): Promise<Channel[]> => {
    const promises = channels.map((channel) =>
      channelService.create({
        name: channel.name,
        username: channel.username,
        socialSetId: stateData.socialSetId,
        externalId: channel.externalId,
        type: channel.type,
        accountType: channel.accountType,
        pictureUrl: channel.pictureUrl,
        config: channel.data as any,
      })
    );

    return Promise.all(promises).then((channels) => {
      refetchQuery([queryNames.socialSets]);
      return channels;
    });
  };

  return (
    <>
      {!isLoading && isError && !isConnecting && (
        <>
          {stateData.action == OAuthConstants.ConnectChannel && (
            <AccountPickerErrorDialog
              channelConfig={channelConfig}
              socialSetId={stateData.socialSetId}
              onCancel={goBack}
            />
          )}

          {stateData.action == OAuthConstants.RefreshChannel && (
            <RefreshChannelErrorDialog
              channelConfig={channelConfig}
              socialSetId={stateData.socialSetId}
              channelId={stateData.channelId}
              externalChannelId={stateData.externalChannelId}
              onCancel={goBack}
            />
          )}
        </>
      )}

      {!isLoading && !isError && !isConnecting && (
        <AccountPickerDialog
          accounts={accountPickerItems}
          onCancel={goBack}
          onConfirm={onChannelsChosen}
          channelConfig={channelConfig}
        />
      )}

      {isLoading && (
        <div className="w-full h-dvh flex flex-col justify-center absolute left-0 top-0 right-0 bottom-0">
          <Spinner />
          <div className="text-sm text-center leading-8 text-gray-600">
            {stateData.action == OAuthConstants.ConnectChannel
              ? "Fetching channels"
              : "Fetching channel data"}
          </div>
        </div>
      )}

      {isConnecting && (
        <div className="w-full h-dvh flex flex-col justify-center  absolute left-0 top-0 right-0 bottom-0">
          <Spinner />
          <div className="text-sm text-center leading-8 text-gray-600">
            {stateData.action == OAuthConstants.ConnectChannel
              ? "Connecting channels"
              : "Refreshing channel connection"}
          </div>
        </div>
      )}
    </>
  );
}
