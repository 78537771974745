import { SparklesIcon } from "@heroicons/react/24/outline";
import { ChannelType } from "../../../../../models/entities/post";
import { getChannelTypeImageUrlFromType } from "../../../../../data/channels-config";

export interface HeaderProps {
  channelType: ChannelType;
  onClose: () => void;
}

export default function AiAssistantHeader({
  channelType,
  onClose,
}: HeaderProps) {
  return (
    <>
      <SparklesIcon className="w-5 h-5 text-primary-500" />
      <div className="text-lg font-semibold leading-6 inline-block text-transparent bg-clip-text bg-gradient-to-r from-primary-500 via-primary-500 to-secondary-500">
        AI Caption Generator
      </div>

      <div className="flex flex-row items-center gap-4 ml-auto">
        {channelType && (
          <img
            className="w-4 h-4 mt-1"
            src={getChannelTypeImageUrlFromType(channelType)}
          />
        )}

        <span
          onClick={onClose}
          className="ml-auto text-3xl text-gray-900 font-medium leading-none cursor-pointer hover:text-gray-600"
        >
          ×
        </span>
      </div>
    </>
  );
}
