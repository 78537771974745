/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import UserUpdateFormInput from "../../../../models/form-inputs/user-update-input";
import Button from "../../../../components/common/Button";
import UserDetailsSection from "../create/components/UserDetailsSection";
import PermissionsSection from "../create/components/PermissionsSection";
import SocialSetsSection from "../create/components/SocialSetsSection";
import ValidationMessages from "../../../../data/validation-messages";

const schema = yup
  .object({
    name: yup
      .string()
      .max(120, ValidationMessages.FullNameLength(120))
      .required(ValidationMessages.FullNameRequired),
    email: yup
      .string()
      .required(ValidationMessages.EmailRequired)
      .email(ValidationMessages.EmailFormat),
  })
  .required();

interface EditUserFormProps {
  input?: UserUpdateFormInput;
  onSubmit: (input: UserUpdateFormInput) => void;
  isSubmitting: boolean;
  isEdit: boolean;
}

export default function EditUserForm({
  input,
  onSubmit,
  isSubmitting,
  isEdit,
}: EditUserFormProps) {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);

  const form = useForm<UserUpdateFormInput>({
    resolver: yupResolver(schema) as any,
    defaultValues: input,
  });

  const { formState, handleSubmit, watch } = form;

  const submitForm = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    const validate = (values: UserUpdateFormInput) => {
      setIsValid(
        formState.isValid &&
          (values.socialSetIds?.length > 0 || values.socialSetIds === null) &&
          (values.permissions?.length > 0 || values.permissions === null)
      );
    };

    const { unsubscribe } = watch((values) => {
      validate(values);
    });
    return () => unsubscribe();
  }, [formState.isValid, watch]);

  return (
    <>
      <div className="border-b border-gray-200 bg-white pb-5">
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              {isEdit ? "Edit User" : "Invite User"}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {isEdit
                ? "Edit user info or permissions"
                : "Invite a new user to join your team."}
            </p>
          </div>
        </div>
      </div>

      <UserDetailsSection form={form} onSubmit={onSubmit} />

      <div className="py-6 mt-2 border-t border-gray-100">
        <div className="text-base font-normal leading-7 text-gray-900">
          Permissions
        </div>
        <div className="text-sm text-gray-500">
          Set the permissions for the user. They will be able to perform the
          actions you allow them to.
        </div>

        <PermissionsSection
          form={form}
          defaultValue={input?.permissions ?? []}
        />
      </div>

      <div className="py-6 mt-2 border-t border-gray-100">
        <div className="text-base font-normal leading-7 text-gray-900">
          Social Sets
        </div>
        <div className="text-sm text-gray-500">
          Set the social sets for the user to access.
        </div>

        <SocialSetsSection
          form={form}
          defaultValue={input?.socialSetIds ?? []}
        />
      </div>

      <div className="border-t border-gray-200 bg-white pt-5 -mx-4 sm:-mx-6">
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap  px-4 sm:px-6">
          <div className=""></div>
          <div className="flex-shrink-0 gap-2 flex">
            <Button
              variant="text"
              color="brand"
              className="w-24"
              text="Cancel"
              onClick={() => navigate(-1)}
            />
            <Button
              text="Save"
              variant="solid"
              color="brand"
              className="w-24"
              type="button"
              disabled={!isValid}
              onClick={submitForm}
              loading={isSubmitting}
            />
          </div>
        </div>
      </div>
    </>
  );
}
