import clsx from "clsx";
import Channel from "../../../../models/entities/channel";
import { PostInstance } from "../models/post-instance";
import { Link } from "react-router-dom";
import alertService from "../../../../services/alert/alert-service";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import {
  getChannelImageUrl,
  getChannelTypeImageUrl,
} from "../../../../data/channels-config";

interface ChannelsSelector2Props {
  postInstanceMap: Record<string, PostInstance>;
  postInstances: PostInstance[];
  channels: Channel[];
  isTwitterEnabled: boolean;
  onSelected: (selection: Channel[]) => void;
}

export default function ChannelsSelector2({
  postInstanceMap,
  postInstances,
  channels,
  isTwitterEnabled,
  onSelected,
}: ChannelsSelector2Props) {
  const toggleChannel = (channel: Channel) => {
    if (channel.status == "Expired") {
      alertService.info(
        `The ${channel.type} channel has expired. Please reconnect the channel to continue posting to it.`
      );
      return;
    }

    if (channel.type == "Twitter" && !isTwitterEnabled) {
      alertService.info(
        "Posting limit exceeded. You can post a maximum of 10 X posts for each day."
      );
      return;
    }

    let updated = [];

    if (postInstanceMap[channel.id]) {
      updated = postInstances
        .filter((c) => c.channel.id != channel.id)
        .map((x) => x.channel);
    } else {
      updated = [...postInstances.map((x) => x.channel), channel];
    }

    onSelected(updated);
  };

  return (
    <>
      <div className="flex gap-6 p-2">
        {channels.map((channel) => (
          <div
            key={channel.id}
            className="h-[38px] w-[38px]"
            data-tooltip-id="tooltip-placeholder"
            data-tooltip-delay-show={300}
            data-tooltip-content={channel.name}
          >
            <div
              onClick={() => toggleChannel(channel)}
              className={clsx(
                "group text-sm flex items-center justify-between rounded-full outline-2 outline-offset-2 cursor-pointer hover:grayscale-0 transition-all",
                postInstanceMap[channel.id]
                  ? "grayscale-0 outline"
                  : "grayscale",
                channel.type == "Facebook" ? "outline-[#1977f2]" : "",
                channel.type == "Instagram" ? "outline-[#f00376]" : "",
                channel.type == "TikTok" ? "outline-[#3d3d3d]" : "",
                channel.type == "Pinterest" ? "outline-[#f00376]" : "",
                channel.type == "YouTube" ? "outline-[#ff0000]" : "",
                channel.type == "Twitter" ? "outline-[#000000]" : ""
              )}
            >
              <div className="flex-1 flex items-center min-w-0 gap-x-4">
                <div className="relative">
                  <img
                    referrerPolicy="no-referrer"
                    className="h-[38px] w-[38px] flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                    src={getChannelImageUrl(channel)}
                  />
                  <img
                    className="h-6 w-6 flex-none rounded-full border border-white bg-gray-50 absolute top-5 right-0 -mr-2"
                    src={getChannelTypeImageUrl(channel)}
                  />
                  {channel.status == "Expired" && (
                    <div
                      className="absolute -top-1 -left-1 flex items-center gap-1 rounded-full bg-red-100 p-0.5 text-xs text-red-700 truncate"
                      data-tooltip-id="tooltip-placeholder"
                      data-tooltip-content={`Connection Expired - ${channel.errorMessage}`}
                    >
                      <ExclamationCircleIcon className="w-4" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        {!channels.length ? (
          <div>
            <Link
              to="/social-sets"
              className="group text-sm flex items-center justify-between py-2 px-2 rounded-md hover:bg-gray-50 cursor-pointer"
            >
              <div className="flex-1 flex items-center min-w-0 gap-x-4">
                <div className="flex flex-col gap-2 font-medium text-sm text-slate-600">
                  No channels found in the current social set. Please connect
                  some channels first or switch your current social set.{" "}
                  <span className="font-semibold">Connect now</span>
                </div>
              </div>
            </Link>
          </div>
        ) : null}
      </div>
    </>
  );
}
