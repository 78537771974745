import clsx from "clsx";
import { CalendarEvent } from "../../models/calendar-event";
import PostPreviewTileContent from "../PostPreviewTileContent";
import DraftPostButtons from "./DraftPostButtons";
import FailurePostButtons from "./FailurePostButtons";
import PublishedPostButtons from "./PublishedPostButtons";
import ScheduledPostButtons from "./ScheduledPostButtons";
import MetricsViewMode from "../../models/metrics-view-mode";

interface PostPreviewTileProps {
  event: CalendarEvent;
  isSubmitting: boolean;
  flat: boolean;
  showSlotInfo?: boolean;
  metricsViewMode: MetricsViewMode;
  onDelete: (event: CalendarEvent) => void;
  onEdit: (event: CalendarEvent) => void;
  onClone: (event: CalendarEvent) => void;
  publishNow: (event: CalendarEvent) => void;
  moveToDraft: (event: CalendarEvent) => void;
  moveToScheduled: (event: CalendarEvent) => void;
}

export default function PostPreviewTile({
  event,
  isSubmitting,
  flat,
  metricsViewMode,
  onDelete,
  onEdit,
  onClone,
  publishNow,
  moveToDraft,
  moveToScheduled,
}: PostPreviewTileProps) {
  return (
    <>
      <div
        className={clsx(
          "bg-white rounded-lg",
          !flat && "shadow-lg ring-1 ring-black/5"
        )}
      >
        <div className="overflow-hidden rounded-t-lg">
          <PostPreviewTileContent
            post={event.post}
            metricsViewMode={metricsViewMode}
          />
        </div>
        <div className="flex justify-end items-center gap-1 p-2">
          {event.post.status == "Published" && (
            <PublishedPostButtons
              post={event.post}
              onClone={() => onClone(event)}
            />
          )}

          {event.post.status == "Draft" && (
            <DraftPostButtons
              primaryLoading={isSubmitting}
              onClone={() => onClone(event)}
              onEdit={() => onEdit(event)}
              onDelete={() => onDelete(event)}
              onPublishNow={() => publishNow(event)}
              onMoveToDraft={() => moveToDraft(event)}
              onMoveToScheduled={() => moveToScheduled(event)}
            />
          )}

          {(event.post.status == "Scheduled" ||
            event.post.status == "Processed") && (
            <ScheduledPostButtons
              primaryLoading={isSubmitting}
              onClone={() => onClone(event)}
              onEdit={() => onEdit(event)}
              onDelete={() => onDelete(event)}
              onPublishNow={() => publishNow(event)}
              onMoveToDraft={() => moveToDraft(event)}
            />
          )}

          {(event.post.status == "ProcessingFailed" ||
            event.post.status == "PublishFailed") && (
            <FailurePostButtons
              primaryLoading={isSubmitting}
              onClone={() => onClone(event)}
              onDelete={() => onDelete(event)}
              onEdit={() => onEdit(event)}
            />
          )}

          {(event.post.status == "Processing" ||
            event.post.status == "ProcessingEnqueued") && (
            <div className="flex justify-center items-center gap-2 w-full animate-pulse">
              <div className="w-2 h-2 bg-pink-600 rounded-full -ml-2" />
              <span>Preparing</span>
            </div>
          )}

          {(event.post.status == "Publishing" ||
            event.post.status == "PublishingEnqueued") && (
            <div className="flex justify-center items-center gap-2 w-full animate-pulse">
              <div className="w-2 h-2 bg-purple-600 rounded-full -ml-2" />
              <span>Sending</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
