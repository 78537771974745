import dayjs from "dayjs";
import Post from "../../../../models/entities/post";
import { AttachmentType } from "../../../../models/entities/attachment";
import { PlayCircleIcon, Square2StackIcon } from "@heroicons/react/20/solid";
import PostStatusLabel from "./views/PostStatusLabel";
import {
  getChannelImageUrl,
  getChannelTypeImageUrl,
} from "../../../../data/channels-config";
import useCurrentSocialSet from "../../../../hooks/useCurrentSocialSet";
import { ClockIcon } from "@heroicons/react/24/outline";
import Metrics from "./metrics/Metrics";
import MetricsViewMode from "../models/metrics-view-mode";

export interface PostPreviewTileContentProps {
  post: Post;
  metricsViewMode: MetricsViewMode;
}
export default function PostPreviewTileContent({
  post,
  metricsViewMode,
}: PostPreviewTileContentProps) {
  const socialSet = useCurrentSocialSet();

  return (
    <div className="cursor-default">
      {(post.status == "ProcessingFailed" ||
        post.status == "PublishFailed") && (
        <div className="bg-red-700 px-3 py-2 text-white">
          <div className="text-xs font-medium text-red-100">
            {post?.error?.message ??
              "Something went wrong with this post. Please retry."}
          </div>
          {post.error?.description && (
            <div className="mt-1 text-xs text-red-200">
              {post.error.description}
            </div>
          )}
        </div>
      )}

      <div className="bg-white px-4 py-3 sm:px-4">
        <div className="flex space-x-5 items-center">
          <div className="relative">
            <img
              referrerPolicy="no-referrer"
              className="h-8 w-8 flex-none rounded-full shadow-sm"
              src={getChannelImageUrl(post.channel)}
            />
            <img
              className="h-4 w-4 flex-none rounded-full border border-white border-3 bg-gray-50 absolute top-6 right-0 -mr-2"
              src={getChannelTypeImageUrl(post.channel)}
            />
          </div>
          <div className="min-w-0 flex-1">
            <div className="text-sm font-semibold text-gray-900">
              <div className="">{post.channel.name}</div>
            </div>
            <div className="text-xs text-gray-500">
              <div className="flex flex-row items-center gap-2 font-light leading-3">
                <div>
                  {" "}
                  {dayjs(post.scheduledAt)
                    .tz(socialSet.timezone)
                    .format("MMM DD, YYYY, hh:mm A")}
                </div>

                {!post.slotId && (
                  <div
                    className="flex flex-row items-center gap-1"
                    data-tooltip-id="tooltip-placeholder"
                    data-tooltip-html="Schedlued for a custom time, outside of the<br> fixed posting times for this channel queue"
                  >
                    <div>
                      <ClockIcon className="w-4 h-4" />
                    </div>
                    <div>Custom</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-1 relative px-4 py-3 sm:px-4 min-h-20 max-h-52 overflow-auto  border-t border-gray-100">
        <div className="flex-1 flex flex-col justify-between">
          {post.title?.length > 0 && (
            <div className="font-normal text-sm text-slate-700 flex-1">
              {post.title}
            </div>
          )}

          <div className="sticky -bottom-3 left-0 py-2 bg-white">
            <PostStatusLabel status={post.status} />
          </div>
        </div>
        <div className="w-1/3 max-w-[120px] sticky top-0 right-0">
          {post.postAttachments.length > 0 && (
            <div className="ml-auto relative rounded-lg bg-white shadow-md overflow-hidden aspect-1 w-full">
              {post.postAttachments[0].attachment.type ==
              AttachmentType.Photo ? (
                <>
                  <img
                    className="flex-none object-cover w-full h-full aspect-1"
                    src={
                      post.postAttachments[0].attachment.thumbnails?.small
                        ?.url ?? post.postAttachments[0].attachment.info.url
                    }
                  />
                  {post.postAttachments.length > 1 ? (
                    <div className="absolute left-0 right-0 top-0 bottom-0 flex items-end pl-0.5 pb-0.5 justify-start bg-black/30">
                      <Square2StackIcon className="w-4 text-white" />
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <img
                    className="flex-none object-cover w-full h-full aspect-1"
                    src={
                      post.postAttachments[0].attachment.thumbnails?.small?.url
                    }
                  />
                  <div className="absolute left-0 right-0 top-0 bottom-0 flex items-end pl-0.5 pb-0.5 justify-start bg-black/30">
                    <PlayCircleIcon className="w-6 text-white" />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <Metrics post={post} metricsViewMode={metricsViewMode} />

      <div className="border-t border-gray-100"></div>
    </div>
  );
}
